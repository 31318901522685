<template>
<vs-row v-if="$parent.user != null" style="padding-top: 50px;" class="store" justify="space-between">
    <input type="file" ref="imageInput" style="display: none;" accept="image/*" @change="fileInputChange" />

    <vs-dialog blur prevent-close not-close>
        <template #header>
            <h4 style="margin-bottom: 0;">
                Oh no!
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Ci stiamo ancora lavorando!</p>
            </vs-row>
            <vs-row justify="center">
                <p style="margin: 0;">La funzionalità è ancora in fase di sviluppo, riprova più avanti!</p>
            </vs-row>
            <vs-row justify="center" style="margin-top: 20px; margin-bottom: 20px;">
                <img style="width: 40%;" src="../assets/undraw_under_construction_-46-pa 1.png" />
            </vs-row>
        </div>

        <template #footer>
            <div class="footer-dialog">

                <div class="new">
                    Maggiori informazioni? <a>Cookie policy</a>
                </div>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="themeChangeDialog" blur prevent-close :loading="themeChangeDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Attenzione!
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Il cambio di tema comporta una rielaborazione di tutte le pagine aggiunte fino ad ora. Nessun contenuto verrà perso ma la formattazione dei testi verrà ripristinata con quella originaria.</p>
            </vs-row>
            <vs-row justify="center">
                <p style="margin: 0;">Questa operazione può richiedere un po' di tempo, sei sicuro di voler proseguire?</p>
            </vs-row>
            <vs-row justify="center" style="margin-top: 20px; margin-bottom: 20px;">
                <img style="width: 40%;" src="../assets/book_render.png" />
            </vs-row>
        </div>

        <template #footer>
            <vs-row justify="space-between">
                <vs-button style="width: 49%; margin:0;" transparent @click="changeTheme(false)">
                    Torna indietro
                </vs-button>
                <vs-button style="width: 49%; margin:0;" @click="changeTheme(true)">
                    Procediamo!
                </vs-button>
            </vs-row>

        </template>
    </vs-dialog>

    <vs-dialog v-model="confirmProductUpgradeDialog" blur prevent-close>
        <template #header>
            <h4 style="margin-bottom: 0;">
                Complimenti!
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Hai raggiunto un numero di pagine sufficienti per poter proseguire al checkout. Ora puoi scegliere se con continuare la configurazione del tuo prodotto o procedere con l'acquisto.</p>
                <p style="margin:0;">Ricorda che se decidi di procedere al checkout, non sarà più possibile tornare alla configurazione per modificare il prodotto.</p>
            </vs-row>
        </div>

        <template #footer>
            <vs-row justify="space-between">
                <vs-button style="width: 49%; margin:0;" transparent @click="() => {confirmProductUpgradeDialog = false; addPage(); }">
                    Continua la configurazione
                </vs-button>
                <vs-button style="width: 49%; margin:0;" @click="checkout()">
                    Procedi al checkout
                </vs-button>
            </vs-row>

        </template>
    </vs-dialog>

    

    <vs-dialog v-model="layoutChooseDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Scegli un <b>modello</b>
            </h4>

        </template>

        <vs-row v-if="product != null">

            <vs-col w="3">
                <vs-select placeholder="Seleziona.." label="Tema" block v-model="layoutThemeTemp">
                    <vs-option v-for="theme in product.themes" :key="'theme-' + theme.id" :label="theme.title" :value="theme.id">
                        {{ theme.title }}
                    </vs-option>
                </vs-select>
            </vs-col>

        </vs-row>
        <vs-row>
            <vs-col w="4" v-for="layout in layouts.filter(e => e.layoutFiles.find(x => x.themeId == layoutTheme) != null && !e.isBackCover && !e.isFrontCover && !e.isBlankPage)" :key="'layout-' + layout.id">
                <div @click="layoutChooseDialogValue = layout.id" class="image-box-sel" :class="{'selected': layout.id == layoutChooseDialogValue}" :style="{'background-image': 'url(' + baseUrl + '/Layout/File/' + layout.layoutFiles.find(x => x.themeId == layoutTheme).previewFileId + ')'}"></div>
            </vs-col>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="addPageCore()" :disabled="layoutChooseDialogValue == null || pageFlipping || goingToPage">
                    Conferma selezione
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="elementEditDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Personalizza il tuo <b>testo</b>
            </h4>

        </template>

        <vs-row style="gap: 10px; margin-bottom: 20px; margin-top: 20px;" v-if="elementEditDialogData != null && elementReference != null && elementReference.type == 'TEXT'">
            <vs-col w="2">
                <vs-select placeholder="Seleziona.." label="Font" block v-model="elementEditDialogData.font">
                    <vs-option v-for="font in fonts" :key="'font-' + font.id" :label="font.name" :value="font.id">
                        {{ font.name }}
                    </vs-option>
                </vs-select>
            </vs-col>

            <vs-button icon :transparent="elementEditDialogData.text_alignment_vertical != 'TOP'" @click="elementEditDialogData.text_alignment_vertical = 'TOP'"><i class='bx bx-vertical-top'></i></vs-button>
            <vs-button icon :transparent="elementEditDialogData.text_alignment_vertical != 'MIDDLE'" @click="elementEditDialogData.text_alignment_vertical = 'MIDDLE'"><i class='bx bx-vertical-center'></i></vs-button>
            <vs-button icon :transparent="elementEditDialogData.text_alignment_vertical != 'BOTTOM'" @click="elementEditDialogData.text_alignment_vertical = 'BOTTOM'"><i class='bx bx-vertical-bottom'></i></vs-button>

            <vs-button icon :transparent="elementEditDialogData.text_alignment_horizontal != 'LEFT'" @click="elementEditDialogData.text_alignment_horizontal = 'LEFT'"><i class='bx bx-align-left'></i></vs-button>
            <vs-button icon :transparent="elementEditDialogData.text_alignment_horizontal != 'CENTER'" @click="elementEditDialogData.text_alignment_horizontal = 'CENTER'"><i class='bx bx-align-middle'></i></vs-button>
            <vs-button icon :transparent="elementEditDialogData.text_alignment_horizontal != 'RIGHT'" @click="elementEditDialogData.text_alignment_horizontal = 'RIGHT'"><i class='bx bx-align-right'></i></vs-button>

            <vs-col w="2">
                <vs-input type="color" label="Colore" v-model="elementEditDialogData.font_color" />
            </vs-col>
            <vs-col w="2">
                <vs-input type="number" label="Dimensione" v-model="elementEditDialogData.font_size" />
            </vs-col>

        </vs-row>
        <vs-row v-if="elementEditDialogData != null">
            <textarea style="min-height: 20vh;" v-model="elementEditDialogData.content" placeholder="A cosa stai pensando?" :style="{
                fontSize: elementEditDialogData != null && elementEditDialogData.font_size != undefined ? (elementEditDialogData.font_size + 'pt') : undefined,
                color: elementEditDialogData != null && elementEditDialogData.font_color != undefined && elementEditDialogData.font_color != '#ffffff' ? (elementEditDialogData.font_color) : undefined
            }"></textarea>

        </vs-row>

        <vs-row v-if="elementReference != null && elementReference.type == 'QR' && elementEditDialogData != null" justify="center" style="background: #262626;">
            <vue-qrcode :value="elementEditDialogData.content" :color="{ dark: elementReference.defaultFontColor == undefined ? '#ffffffff' : elementReference.defaultFontColor, light: '#00000000' }" type="image/png" @change="qrCodeChanged" width="200" />
            <img :src="qrCode" />
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="customizeTextElementSave">
                    Conferma modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="elementChooseDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Scegli un <b>elemento</b>
            </h4>

        </template>

        <!-- SORT AND FILTER -->
        <vs-row justify="space-between">
            <vs-button v-if="elementReference != null && elementReference.type == 'IMAGE'" @click="$refs.imageInput.click()" :loading="uploading">
                <i class='bx bx-upload bx-sm'></i> Carica immagine
            </vs-button>
            <vs-button v-if="elementReference != null && elementReference.type == 'TEXT'" @click="skipPostSelection(elementReference)">
                <i class='bx bxs-edit-alt bx-sm'></i> Testo personalizzato
            </vs-button>
            <vs-tooltip bottom shadow not-hover v-model="activeFilterTooltip" :loading="postLoading">
                <vs-button icon transparent @click="activeFilterTooltip = true"><i class='bx bx-filter-alt bx-sm'></i></vs-button>
                <template #tooltip>
                    <vs-row style="margin-top: 25px;">
                        <vs-input type="date" label="Dal" block v-model="postFilter.fromDate"></vs-input>
                    </vs-row>
                    <vs-row style="margin-top: 25px;">
                        <vs-input type="date" label="Al" block v-model="postFilter.toDate"></vs-input>
                    </vs-row>

                    <vs-row>
                        <vs-button block transparent v-if="postFilter.sort == 'DESC'" @click="postFilter.sort = 'ASC'"><i class='bx bx-sort-up bx-sm'></i> Decrescente</vs-button>
                        <vs-button block transparent v-if="postFilter.sort == 'ASC'" @click="postFilter.sort = 'DESC'"><i class='bx bx-sort-down bx-sm'></i> Crescente</vs-button>
                    </vs-row>
                    <vs-row>
                        <vs-button block @click="resetFilters"><i class='bx bx-eraser bx-sm'></i> Pulisci filtri</vs-button>
                    </vs-row>
                </template>
            </vs-tooltip>

        </vs-row>
        <vs-row class="posts" style="height: 50vh; overflow-y: auto; overflow-x: hidden; padding: 20px;">
            <template v-if="posts != null">
                <!-- ONLY IMAGE -->
                <template v-if="elementReference != null && elementReference.type == 'IMAGE'">

                    <vs-col w="3" v-for="post in posts" :key="'post-' + post.id">
                        <div @click="elementChooseDialogValue = post" class="image-box-sel" :class="{'selected': post == elementChooseDialogValue}" :style="{'background-image': 'url(' + post.imageUrl + ')'}"></div>
                    </vs-col>
                </template>

                <!-- ONLY TEXT -->
                <template v-if="elementReference != null && elementReference.type == 'TEXT'">
                    <vs-row v-for="(p,k) in posts" :key="'post-' + k">
                        <vs-col w="12">
                            <Post v-on:click.native="elementChooseDialogValue = p" :title="(p.emozione != null ? ('#' + p.emozione) : p.momento != null ? ('Sta' + p.momento) : '')" :caption="p.caption" :emoji="(p.momento != null ? p.momento : null)" :image="p.imageUrl" :created="p.createdAt" :selected="elementChooseDialogValue == p" />

                        </vs-col>
                    </vs-row>
                </template>
            </template>

        </vs-row>
        <vs-row justify="center" style="margin-top: 20px;">
            <vs-pagination v-model="currentPostsPage" :length="totalPostPages" />
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="chooseElementCore" :disabled="elementChooseDialogValue == null">
                    Conferma selezione
                </vs-button>
            </div>
        </template>
    </vs-dialog>

    <vs-dialog v-model="elementCropDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Ritaglia la tua <b>immagine</b>
            </h4>

        </template>

        <vs-row>
            <div class="cropper-wrapper">
                <div :style="{backgroundImage: 'url(' + elementCropSource + ')'}" class="image-background"></div>
                <cropper v-if="elementCropSource != null && elementCropDialog" class="cropper" :src="elementCropSource" :stencil-props="{aspectRatio: elementCropRatio}" @change="onCropChange" />
            </div>

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="confirmCrop">
                    Conferma modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>
    <div style="height:80vh; width: 20%; overflow: hidden; overflow-y: scroll;">

        <vs-row direction="column" style="width: 100%; padding-left: 50px; direction:rtl; padding-right: 20px; gap: 20px; overflow: hidden; overflow-y: hidden; min-height: 100%;" :class="{'disabled-section': pageFlipping || goingToPage || rendering}">
            <vs-button block @click="deletePage(currentPage)" v-if="pages[currentPage] != undefined && Object.keys(pages[currentPage]).length > 0 && !pages[currentPage].isFrontCover && !pages[currentPage].isBackCover && !pages[currentPage].isBlankPage">
                Elimina
                <i class='bx bx-trash-alt bx-sm' style="margin-right: 5px;"></i>
            </vs-button>
            <vs-card v-for="(element, k) in elements.filter(e => e.page % 2 == 0)" :key="('card-'+k)" style="width: 100%; direction:ltr;">
                <template #title>
                    <h3>{{element.title}}</h3>
                </template>
                <template #img>
                    <vs-row v-on:click.native="chooseElement(element)" justify="center" align="center" direction="column" style="width: 200px; height: 150px;" v-if="element.value == undefined">
                        <vs-row justify="center" align="center">
                            <i class="bx bx-plus bx-md" />
                        </vs-row>
                        <vs-row justify="center" align="center">
                            Seleziona un post!
                        </vs-row>
                    </vs-row>
                    <img v-else-if="element.type == 'IMAGE'" :src="element.value.croppedImage" />
                    <img v-else-if="element.type == 'QR'" style="background: #262626;" :src="element.value.image" />
                    <p v-else-if="element.type == 'TEXT'" style="padding: 10px;">{{ element.value.content }}</p>
                </template>
                <template #text>
                    <p>
                        Pagina {{ element.page }}
                    </p>
                </template>
                <template #interactions v-if="element.value != undefined">
                    <vs-button danger icon @click="removeElementContent(element, $event)">
                        <i class='bx bx-trash-alt'></i>
                    </vs-button>
                    <vs-button primary icon v-if="element.type == 'TEXT' || element.type == 'QR'" @click="customizeTextElement(element)">
                        <i class='bx bx-edit-alt'></i>
                    </vs-button>

                </template>
            </vs-card>
        </vs-row>
    </div>
    <vs-row style="height:calc(80vh - 100px); width: 55%;">
        <flipbook class="flipbook" :pages="pagesPreviews" v-slot="flipbook" ref="flipbook" :ambient="1" :clickToZoom="false" @flip-right-start="() => {pageFlipping = true}" @flip-left-start="() => {pageFlipping = true}" @flip-right-end="pageFlipped" @flip-left-end="pageFlipped" :dragToFlip="false">

            <vs-row justify="center" align="center" style="gap: 50px; margin-bottom: 50px;">
                <!--LO SPOSTEREI QUANDO SCEGLIE LA PAGINA
                    <vs-button>
                    <i class='bx bxs-layout bx-sm'></i> Cambia tema
                </vs-button>-->
                <vs-button @click="addPageMiddleware" :disabled="pageFlipping || goingToPage || product == null || (pages.length - 1) >= product.maxPages || rendering">
                    <i class='bx bx-plus bx-sm'></i> Aggiungi pagina
                </vs-button>
                <vs-button circle icon :disabled="!flipbook.canFlipLeft || pageFlipping || goingToPage || rendering" @click="flipbook.flipLeft"><i class='bx bx-chevron-left bx-sm'></i></vs-button>
                <span class="page-num">
                    Pagina {{ flipbook.page }} di {{ flipbook.numPages }}
                </span>
                <vs-button circle icon :disabled="!flipbook.canFlipRight || pageFlipping || goingToPage || rendering" @click="flipbook.flipRight"><i class='bx bx-chevron-right bx-sm'></i></vs-button>
                <vs-tooltip bottom shadow not-hover v-model="activeCheckoutTooltip" v-if="requiredActions.length == 0">
                    <vs-button icon @click="activeCheckoutTooltip = !activeCheckoutTooltip" :loading="checkoutLoading">
                        <i class='bx bx-cart-alt bx-sm'></i> Procedi al checkout
                    </vs-button>
                    <template #tooltip>
                        <div class="content-tooltip">
                            <h4 class="center">
                                Conferma
                            </h4>
                            <p>
                                Una volta eseguito il checkout non sarà più possibile modificare la configurazione del tuo prodotto, sei sicuro di voler proseguire?
                            </p>
                            <footer>
                                <vs-button @click="activeCheckoutTooltip=false" transparent block style="margin: 0; margin-bottom: 5px;">
                                    Annulla
                                </vs-button>
                                <vs-button @click="() => { checkout(); }" block style="margin: 0;">
                                    Prosegui
                                </vs-button>
                            </footer>
                        </div>
                    </template>
                </vs-tooltip>

                <vs-tooltip border-thick color="#b648f6" v-else>
                    <vs-button icon disabled>
                        <i class='bx bx-cart-alt bx-sm'></i> Procedi al checkout
                    </vs-button>
                    <template #tooltip>
                        <template v-if="requiredActions[0] != undefined">
                            <template v-if="requiredActions[0] > 0">
                                <!-- PAGINE DA AGGIUNGERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    Sono necessarie ancora {{ Math.abs(requiredActions[0]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    È necessaria ancora {{ Math.abs(requiredActions[0]) }} pagina.
                                </template>
                            </template>
                            <template v-else>
                                <!-- PAGINE DA TOGLIERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    È necessario rimuovere {{ Math.abs(requiredActions[0]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    È necessario rimuovere {{ Math.abs(requiredActions[0]) }} pagina.
                                </template>
                            </template>
                        </template>

                        <template v-if="requiredActions[1] != undefined">
                            <br />
                            <template v-if="requiredActions[1] > 0">
                                <!-- PAGINE DA AGGIUNGERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    In alternativa aggiungere {{ Math.abs(requiredActions[1]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    In alternativa aggiungere {{ Math.abs(requiredActions[1]) }} pagina.
                                </template>
                            </template>
                            <template v-else>
                                <!-- PAGINE DA TOGLIERE-->
                                <template v-if="Math.abs(requiredActions[1]) > 1">
                                    <!-- PLURALE-->
                                    In alternativa rimuovere {{ Math.abs(requiredActions[1]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    In alternativa rimuovere {{ Math.abs(requiredActions[1]) }} pagina.
                                </template>
                            </template>
                        </template>

                    </template>
                </vs-tooltip>

            </vs-row>

        </flipbook>
    </vs-row>
    <div style="height:80vh; width: 20%; overflow: hidden; overflow-y: scroll; direction: rtl;">
        <vs-row direction="column" style="width: 100%; padding-right: 50px; padding-left: 20px; gap: 20px; overflow: hidden; overflow-y: hidden;  min-height: 100%;" :class="{'disabled-section': pageFlipping || goingToPage || rendering}">
            <vs-button block @click="deletePage(currentPage + 1)" v-if="pagesPreviews[currentPage-1] != null /*COVER MOSTRATA SINGOLA*/ && pages[currentPage + 1] != undefined && Object.keys(pages[currentPage + 1]).length > 0 && !pages[currentPage + 1].isFrontCover && !pages[currentPage + 1].isBackCover && !pages[currentPage + 1].isBlankPage">
                Elimina
                <i class='bx bx-trash-alt bx-sm' style="margin-right: 5px;"></i>
            </vs-button>
            <vs-card v-for="(element, k) in elements.filter(e => e.page % 2 != 0)" :key="('card-'+k)" style="width: 100%; direction: ltr;">
                <template #title>
                    <h3>{{element.title}}</h3>
                </template>
                <template #img>
                    <vs-row v-on:click.native="chooseElement(element)" justify="center" align="center" direction="column" style="width: 200px; height: 200px;" v-if="element.value == undefined">
                        <vs-row justify="center" align="center">
                            <i class="bx bx-plus bx-md" />
                        </vs-row>
                        <vs-row justify="center" align="center">
                            Seleziona un post!
                        </vs-row>
                    </vs-row>
                    <img v-else-if="element.type == 'IMAGE'" :src="element.value.croppedImage" />
                    <img v-else-if="element.type == 'QR'" :src="element.value.image" style="background: #262626;" />
                    <p v-else-if="element.type == 'TEXT'" style="padding: 10px;">{{ element.value.content }}</p>
                </template>
                <template #text>
                    <p>
                        Pagina {{ element.page }}
                    </p>
                </template>
                <template #interactions v-if="element.value != undefined">
                    <vs-button danger icon @click="removeElementContent(element, $event)">
                        <i class='bx bx-trash-alt'></i>
                    </vs-button>
                    <vs-button primary icon v-if="element.type == 'TEXT' || element.type == 'QR'" @click="customizeTextElement(element)">
                        <i class='bx bx-edit-alt'></i>
                    </vs-button>

                </template>
            </vs-card>
        </vs-row>
    </div>
</vs-row>
</template>

<script>
import Flipbook from '../components/flipbook.es.js'
import {
    apiCall,
    baseUrl
} from '../client';
import {
    Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Post from '../components/Post';
import VueQrcode from 'vue-qrcode'

export default ({
    props: {
        productId: Number
    },
    components: {
        Flipbook,
        Cropper,
        Post,
        VueQrcode
    },

    setup() {

    },

    data() {
        return {
            layouts: [],
            layoutChooseDialog: false,
            layoutChooseDialogValue: null,
            layoutTheme: '',
            layoutThemeTemp: '',
            pagesPreviews: [null, ],
            pages: [{}, ],
            currentPage: 1,
            baseUrl,

            elements: [],

            posts: [],

            elementChooseDialog: false,
            elementChooseDialogValue: null,
            elementReference: null,

            elementCropDialog: false,
            elementCropSource: null,
            elementCropCoords: null,
            elementCropPreview: null,
            elementCropRatio: 1,

            elementEditDialog: false,
            elementEditDialogData: null,

            product: null,
            productVariant: null,

            qrCode: '',

            fonts: [],

            themeChangeDialog: false,
            themeChangeDialogLoading: false,

            activeFilterTooltip: false,

            postFilter: {
                sort: 'DESC',
                fromDate: null,
                toDate: null,
                showHidden: true
            },

            currentPostsPage: 1,
            totalPostPages: 1,

            uploading: false,

            hasBackCover: false,
            hasEmptyPage: false,

            postLoading: false,

            canExit: false,

            requiredActions: [0],

            activeCheckoutTooltip: false,
            checkoutLoading: false,

            pageFlipping: false,
            goingToPage: false,

            confirmProductUpgradeDialog: false,

            rendering: false,
        }
    },

    methods: {

        deletePage(pageNo) {
            // ensure that the page is not a cover, empty page or null
            if (this.pagesPreviews[pageNo] == null || this.pages[pageNo].isBackCover || this.pages[pageNo].isFrontCover || this.pages[pageNo].isBlankPage) {
                return;
            }

            // delete the page in position pageNo from pages and pagesPreviews
            this.pages.splice(pageNo, 1);
            this.pagesPreviews.splice(pageNo, 1);

            // recalculate page number of all pages's elements
            for (var i = 0; i < this.pages.length; i++) {
                if (this.pages[i].elements == undefined) {
                    continue;
                }
                this.pages[i].elements = this.pages[i].elements.map(e => ({
                    ...e,
                    page: i,
                }));
            }
        },

        async fileInputChange() {
            // if input is empty skip
            if (this.$refs.imageInput.files.length == 0) {
                return;
            }
            const reader = new FileReader();
            const file = this.$refs.imageInput.files[0];
            reader.addEventListener("load", async () => {
                // upload it creating a new post
                this.uploading = true;
                var result = await apiCall('PUT', '/Post', {
                    Id: '',
                    UserId: 0,
                    Emotion: null,
                    FileContent: reader.result.split('base64,')[1],
                    Text: '',
                    User: {},
                    isHidden: true
                });
                this.uploading = false;
                if (result.status == 200) {
                    this.elementChooseDialogValue = {
                        imageUrl: reader.result,
                        fileId: result.data.fileId,
                    };
                    this.chooseElementCore();
                } else {
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Impossibile caricare l'immagine.`
                    })
                }
                // reset input file
                this.$refs.imageInput.value = '';
            }, false);
            if (file) {
                if (file.size > 1024 * 1024 * 10) {
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `La foto non può essere più grande di 10MB.`
                    })
                    // reset input file
                    this.$refs.imageInput.value = '';
                    return;
                }
                reader.readAsDataURL(file);
            }
        },

        checkout() {
            this.canExit = true;
            this.activeCheckoutTooltip = false;
            this.checkoutLoading = true;
            setTimeout(() => {
                var vm = this;
                this.$router.push({
                    name: 'checkout',
                    params: {
                        pages: vm.pages,
                        product: vm.product
                    }
                });
            }, 1000); // in this way the tooltip has time to close

        },

        qrCodeChanged(e) {
            this.qrCode = e;
        },

        addPage() {
            this.confirmProductUpgradeDialog = false;
            this.layoutChooseDialog = true;
        },

        addPageMiddleware() {
            if ((this.pages.length - 1) >= this.productVariant.pages) {
                this.confirmProductUpgradeDialog = true;
            } else {
                this.addPage();
            }
        },

        skipPostSelection(e) {
            e.value = {
                content: e.defaultText != null ? e.defaultText : '',
                font: e.defaultFontId != null ? e.defaultFontId : '',
                font_size: e.defaultFontSize,
                text_alignment_vertical: e.defaultFontValign != null ? e.defaultFontValign : 'MIDDLE',
                text_alignment_horizontal: e.defaultFontHalign != null ? e.defaultFontHalign : 'CENTER',
                font_color: this.hexToRgb(e.defaultFontColor)
            }
            this.customizeTextElement(e);
        },

        chooseElement(e) {
            if (e.skipPostSelection) {
                this.skipPostSelection(e);
                return;
            }
            this.elementChooseDialogValue = null;
            this.currentPostsPage = 1;
            this.getPosts(e.type)
            this.elementReference = e;
            this.elementChooseDialog = true;
        },

        customizeTextElement(e) {
            this.elementReference = e;
            this.elementEditDialogData = {
                font: e.value.font,
                font_size: e.value.font_size,
                font_color: this.rgbToHex(e.value.font_color),
                text_alignment_vertical: e.value.text_alignment_vertical,
                text_alignment_horizontal: e.value.text_alignment_horizontal,
                content: e.value.content
            };
            this.elementEditDialog = true;
        },

        async customizeTextElementSave() {
            if (this.elementReference.type == 'TEXT') {
                this.elementReference.value.content = this.elementEditDialogData.content;
                this.elementReference.value.font = this.elementEditDialogData.font;
                this.elementReference.value.font_size = this.elementEditDialogData.font_size;
                this.elementReference.value.text_alignment_vertical = this.elementEditDialogData.text_alignment_vertical;
                this.elementReference.value.text_alignment_horizontal = this.elementEditDialogData.text_alignment_horizontal;
                this.elementReference.value.font_color = this.hexToRgb(this.elementEditDialogData.font_color);
            } else if (this.elementReference.type == 'QR') {
                this.elementReference.value.content = this.elementEditDialogData.content; // salvo il contenuto testuale
                this.elementReference.value.image = this.qrCode;
                this.elementReference.value.crop_height = 0;
                this.elementReference.value.crop_width = 0;
                this.elementReference.value.crop_top_left_x = 0;
                this.elementReference.value.crop_top_left_y = 0;
                this.elementReference.value.text_alignment_horizontal = undefined;
                this.elementReference.value.text_alignment_vertical = undefined;
            }

            this.refreshPagePreview(this.elementReference.page);
            this.elementEditDialog = false;
            if (this.elementChooseDialog) {
                this.elementChooseDialog = false;
            }
        },

        async chooseElementCore() {
            // if this is an image let the user crop it
            if (this.elementReference.type == 'IMAGE') {
                this.elementCropRatio = this.elementReference.width / this.elementReference.height;
                this.getImageBlob(this.elementChooseDialogValue.imageUrl)
            } else {
                this.elementReference.value = {
                    content: this.elementChooseDialogValue.caption,
                    font: this.elementReference.defaultFontId != null ? this.elementReference.defaultFontId : '',
                    font_size: this.elementReference.defaultFontSize,
                    text_alignment_vertical: this.elementReference.defaultFontValign != null ? this.elementReference.defaultFontValign : 'MIDDLE',
                    text_alignment_horizontal: this.elementReference.defaultFontHalign != null ? this.elementReference.defaultFontHalign : 'CENTER',
                    font_color: this.hexToRgb(this.elementReference.defaultFontColor)
                }
                this.refreshPagePreview(this.elementReference.page)
                this.elementChooseDialog = false;
            }
        },

        addPageCore(layoutId = null, initialized = true) {
            var vm = this;
            if (layoutId == null) {
                layoutId = this.layoutChooseDialogValue;
            }
            var layout = this.layouts.find(e => e.id == layoutId);

            var pUrl = baseUrl + '/Layout/File/' + layout.layoutFiles.find(x => x.themeId == vm.layoutTheme).previewFileId;
            var p = {
                layoutId: layout.id,
                themeId: vm.layoutTheme,
                isBackCover: layout.isBackCover,
                isFrontCover: layout.isFrontCover,
                isBlankPage: layout.isBlankPage,

                elements: layout.elements.map(e => ({
                    ...e,
                    page: vm.pages.length,
                    defaultFontId: e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme) != null ? e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme).fontId : null,
                    defaultFontColor: e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme) != null ? e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme).color : '#000000',
                    defaultFontSize: e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme) != null ? e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme).size : 14,
                }))
            };

            //this.pagesPreviews.splice(pageNo, 0, pUrl) // TODO TEMA ANZICHE [0]

            //this.pages.splice(pageNo, 0, p)
            if (!initialized) {
                this.pages.push(p);
                this.pagesPreviews.push(pUrl);
            } else {
                // calculate page number and add new page in its position
                var pagesToSkipFromEnd = 0;

                if (this.hasBackCover) {
                    pagesToSkipFromEnd++;
                }

                if (this.hasEmptyPage) {
                    pagesToSkipFromEnd++;
                }

                var pageNo = this.pages.length - pagesToSkipFromEnd;
                p.elements = p.elements.map(e => ({
                    ...e,
                    page: pageNo,
                }));
                this.pages.splice(pageNo, 0, p)
                this.pagesPreviews.splice(pageNo, 0, pUrl)

                // recalculate page number of all pages
                for (var i = 0; i < this.pages.length; i++) {
                    if (this.pages[i].elements == undefined) {
                        continue;
                    }
                    this.pages[i].elements = this.pages[i].elements.map(e => ({
                        ...e,
                        page: i,
                    }));
                }

                this.goToPage(pageNo);
            }

            this.layoutChooseDialogValue = null;
            this.layoutChooseDialog = false;
        },

        isPageVisible(x) {
            var visiblePages = [];
            var sx = this.pages[this.currentPage],
                dx = this.pages[this.currentPage + 1];
            if (sx != undefined && sx.elements != undefined) {
                visiblePages.push(this.currentPage);
            }

            if (this.currentPage != 1) { //  non prima pagina
                if (dx != undefined && dx.elements != undefined) { // se esiste (magari è l'ultima pagina)
                    visiblePages.push(this.currentPage + 1);
                }
            }

            return visiblePages.includes(x);
        },

        goToPage(n) {
            var vm = this;
            if(vm.goingToPage) {
                return;
            }
            vm.goingToPage = true;
            var t = setInterval(() => {
                if (vm.isPageVisible(n)) {
                    clearInterval(t);
                    vm.goingToPage = false;
                } else {
                    if (n > vm.currentPage) {
                        vm.$refs.flipbook.flipRight();
                    } else {
                        vm.$refs.flipbook.flipLeft();
                    }
                }
            }, 1500 /* flip duration = 1000ms */);
        },

        removeElementContent(element, e) {
            e.preventDefault();
            element.value = undefined;
            this.refreshPagePreview(element.page)
        },

        async confirmCrop() {
            this.elementReference.value = {
                content: this.elementChooseDialogValue.fileId != null ? this.elementChooseDialogValue.fileId : this.elementChooseDialogValue.imageUrl.split('base64,')[1],
                crop_top_left_x: this.elementCropCoords.left,
                crop_top_left_y: this.elementCropCoords.top,
                crop_width: this.elementCropCoords.width,
                crop_height: this.elementCropCoords.height,
                croppedImage: this.elementCropPreview,
                font: '',
                font_size: 0
            }
            this.elementCropDialog = false;
            this.elementChooseDialog = false;

            this.refreshPagePreview(this.elementReference.page)
        },

        async refreshPagePreview(pageNo, cb = null) {
            var vm = this;
            var pageLayout = this.pages[pageNo];

            vm.pagesPreviews[pageNo] = baseUrl + '/Post/Media/nothing';
            vm.pagesPreviews.__ob__.dep.notify();
            this.renderPage(pageLayout, function (e) {
                if (e != null) { // rendering andato a buon fine
                    vm.pagesPreviews[pageNo] = e;
                    vm.pagesPreviews.__ob__.dep.notify();
                } else {
                    vm.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Il rendering della pagina ${pageNo} è fallito.`
                    })
                }

                if (cb != null) {
                    cb(e);
                }
            });
        },

        getImageBlob(url) {
            var vm = this;
            fetch(url, {
                    credentials: 'include'
                })
                .then((response) => response.blob())
                .then((response) => {
                    vm.elementCropSource = URL.createObjectURL(response);
                    vm.elementCropDialog = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        onCropChange({
            coordinates,
            canvas
        }) {
            this.elementCropCoords = coordinates;
            this.elementCropPreview = canvas.toDataURL();
        },

        pageFlipped(e) {
            this.currentPage = e;
            setTimeout(() => {
                this.pageFlipping = false;
            }, 1000);
        },

        evaluatePageFields(n) {
            var elements = [];
            var sx = this.pages[n],
                dx = this.pages[n + 1];
            if (sx != undefined && sx.elements != undefined) {
                elements = elements.concat(sx.elements);
            }

            if (n != 1) { //  non prima pagina
                if (dx != undefined && dx.elements != undefined) { // se esiste (magari è l'ultima pagina)
                    elements = elements.concat(dx.elements);
                }
            }

            this.elements = elements;

        },

        async getPosts(type) {
            this.postLoading = true;
            var p = JSON.parse(JSON.stringify(this.postFilter)); // deep copy
            p.page = this.currentPostsPage - 1; // for us it starts from 0
            p.type = type;
            var result = await apiCall('POST', '/Post', p);
            this.postLoading = false;
            if (result.status == 200) {
                var newPosts = result.data.posts.map(function (e) {
                    return {
                        id: e.id,
                        emozione: e.emotion,
                        momento: null,
                        caption: e.text,
                        imageUrl: e.fileId != null ? (baseUrl + '/Post/Media/' + e.fileId) : null,
                        createdAt: new Date(e.createdAt),
                        fileId: e.fileId
                    }
                });
                this.posts = newPosts;
                this.totalPostPages = Math.ceil(result.data.total / 8);
            }
        },

        async renderPage(pageLayout, cb) {
            var vm = this;
            vm.rendering = true;
            pageLayout.themeId = this.layoutTheme; // override themeId with selected theme
            var layoutTemp = JSON.parse(JSON.stringify(pageLayout));
            for (var k in layoutTemp.elements) {
                if (layoutTemp.elements[k].value != undefined) {
                    layoutTemp.elements[k].value.croppedImage = undefined;
                }
            }
            fetch(this.baseUrl + '/Layout/Render', {
                    credentials: 'include',
                    method: 'POST',
                    body: JSON.stringify(layoutTemp),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(function (response) {
                    if (response.status == 200) {
                        return response.blob();
                    }
                    return null;
                })
                .then((response) => {
                    if (response != null) {
                        vm.rendering = false;
                        cb(URL.createObjectURL(response));
                    } else {
                        vm.rendering = false;
                        cb(null);
                    }
                })
                .catch(err => {
                    cb(null);
                    vm.rendering = false;
                    console.log(err);
                });
        },

        componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        },

        rgbToHex(e) {
            return "#" + this.componentToHex(e.r) + this.componentToHex(e.g) + this.componentToHex(e.b);
        },

        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        },

        // reimposta il colore, font, dimensione a quelli predefiniti dell'elemento
        restoreElementValueDefaults(e, value) {
            if (value != null && e.type == 'TEXT') {
                value.font = e.defaultFontId != null ? e.defaultFontId : '';
                value.font_size = e.defaultFontSize;
                value.font_color = this.hexToRgb(e.defaultFontColor)
            }
            return value;
        },

        async changeTheme(confirm) {
            var vm = this;
            if (confirm) {
                this.layoutTheme = this.layoutThemeTemp;
                for (var i in this.pages) {
                    if (this.pages[i].layoutId == undefined) {
                        // pagina vuota
                        continue;
                    }

                    // Aggiorniamo, font, colore, dimensione con quelle del tema
                    this.pages[i].elements = this.pages[i].elements.map(e => ({
                        ...e,
                        defaultFontId: e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme) != null ? e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme).fontId : null,
                        defaultFontColor: e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme) != null ? e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme).color : '#000000',
                        defaultFontSize: e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme) != null ? e.elementDefaultFonts.find(f => f.themeId == vm.layoutTheme).size : 14,
                        value: vm.restoreElementValueDefaults(e, e.value)
                    }))

                    // Per gli elementi già valorizzati, cambio colore font e dimensione con quelli di default appena impostati
                    this.pages[i].elements = this.pages[i].elements.map(e => ({
                        ...e,
                        value: vm.restoreElementValueDefaults(e, e.value)
                    }))
                }
                this.evaluatePageFields(this.currentPage); // aggiorno i riferimenti degli elementi con quelli delle pagine attuali
                this.rerenderAll();
            } else {
                this.layoutThemeTemp = this.layoutTheme;
                this.themeChangeDialog = false;
            }
        },

        rerenderAll(i = 1) {
            this.themeChangeDialogLoading = true;
            if (i >= this.pages.length) {
                this.themeChangeDialog = false;
                this.themeChangeDialogLoading = false;
                return;
            }
            this.refreshPagePreview(i, () => {
                this.rerenderAll(i + 1);
            });
        },

        evaluateProductVariants(pages) {
            if (this.product.productVariants == undefined) {
                return;
            }

            var pv = this.product.productVariants.find(e => e.pages >= pages);
            this.productVariant = pv != undefined ? pv : this.productVariant;

            this.requiredActions = [];

            var perfectMatch = this.product.productVariants.find(e => e.pages == pages);
            if (perfectMatch != null) {
                return;
            }

            var greater = this.product.productVariants.find(e => e.pages > pages);
            if (greater != null) {
                var gDelta = greater.pages - pages;
                this.requiredActions.push(gDelta);
            }

            var lower = this.product.productVariants.find(e => e.pages < pages);
            if (lower != null) {
                var gLower = lower.pages - pages;
                this.requiredActions.push(gLower);
            }
        }

    },

    watch: {
        layoutThemeTemp(n) {
            if (n != this.layoutTheme) {
                // ask to user if is sure to change the theme
                this.themeChangeDialog = true;
            }
        },

        currentPage(n) {
            this.evaluatePageFields(n);
        },

        pages(n) {
            this.evaluatePageFields(this.currentPage)
            this.evaluateProductVariants(n.length - 1) // -1 because the first one is null always
        },

        product(n, o) {
            if (o != null) {
                return;
            }
            // TODO RIFERIMENTI TEMA??
            var blank = this.layouts.find(e => e.isBlankPage);

            var startCover = this.layouts.find(e => e.isFrontCover);
            if (startCover != null) {
                this.addPageCore(startCover.id, false);
            }

            if (n.emptyBackCover && blank != null) {
                this.addPageCore(blank.id, false);
                this.addPageCore(blank.id, false);
                this.hasEmptyPage = true;
            }
            var backCover = this.layouts.find(e => e.isBackCover);
            if (backCover != null) {
                this.addPageCore(backCover.id, false);
                this.hasBackCover = true;
            }

        },

        postFilter: {
            deep: true,
            handler: function () {
                if (this.currentPostsPage > 0) { // se era a una pagina maggiore di zero la imposto a 0, e questo in automatico ritriggera il caricamento del feed
                    this.currentPostsPage = 0;
                } else { // se invece era già a 0, ricarico il feed manualmente
                    this.getPosts(this.elementReference.type);
                }

            }
        },

        currentPostsPage() {
            this.getPosts(this.elementReference.type);
        },
    },

    beforeRouteLeave(to, from, next) {
        if (this.canExit || this.$parent.user == null) {
            next();
            return;
        }
        const answer = window.confirm('Se abbandoni questa pagina tutte le modifiche verranno perse.')
        if (answer) {
            next()
        } else {
            next(false)
        }
    },

    async mounted() {
        if (this.productId == null || this.productId < 1) {
            this.$router.push({
                name: 'feed'
            }); // tentativo di accesso diretto
            return;
        }
        var result = await apiCall('GET', '/Layout');
        if (result.status == 200) {
            this.layouts = result.data.filter(e => e.product.id == this.productId);
            // chiaramente deve esserci almeno un layout per questo prodotto
            if (this.layouts.length > 0) {
                // risalgo al prodotto e mi salvo il riferimento
                this.product = this.layouts[0].product;
                this.layoutTheme = this.product.themes[0].id;
                this.layoutThemeTemp = this.layoutTheme;
            }
        }

        result = await apiCall('GET', '/Font');
        if (result.status == 200) {
            this.fonts = result.data;
        }

        result = await apiCall('GET', '/Product');
        if (result.status == 200) {
            this.product.productVariants = result.data.find(e => e.id == this.productId).productVariants;
            this.evaluateProductVariants(this.pages.length - 1)

            // find the variant with higher number of pages and set product.maxPages to that value
            this.product.maxPages = Math.max.apply(Math, this.product.productVariants.map(function (o) {
                return o.pages;
            }));

            // find the variant with lower number of pages and set it as current productVariant
            this.productVariant = this.product.productVariants.find(e => e.pages == Math.min.apply(Math, this.product.productVariants.map(function (o) {
                return o.pages;
            })));
        }

    },
})
</script>

<style>
.flipbook {
    width: 100%;
    height: calc(80vh - 100px);
}

.flipbook-container {
    margin-bottom: 100px;
}

.flipbook .bounding-box {
    box-shadow: 0 0 20px rgb(145, 145, 145);
}

.image-box-sel {
    padding-bottom: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    opacity: 0.6;
}

.image-box-sel:hover {
    opacity: 0.8;
    cursor: pointer;
}

.image-box-sel.selected {
    opacity: 1;
}

.image-box-sel::before,
.image-box-sel::after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
}

.image-box-sel::before {
    backdrop-filter: blur(10px) brightness(0.8);
}

.image-box-sel::after {
    background-image: inherit;
    background-size: contain;
    background-position: inherit;
    background-repeat: inherit;
}

.chooseLayout .vs-dialog {
    width: 60%;
}

.preview-container {
    width: 500px;
    height: 250px;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.preview-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    filter: blur(10px);
    transform: scale(1.1);
}

.preview-image {
    max-width: 100%;
    max-height: 100%;
}

.store .vs-card__img {
    max-height: 150px;
}

.store .vs-card__img:first-child:not(img) {
    height: 150px;
}

.flipbook .bounding-box {
    pointer-events: none !important;
    cursor: pointer !important;
}

.disabled-section {
    pointer-events: none!important;
    opacity: 0.6;
}
</style>
